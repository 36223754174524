import React, { RefObject, useRef, useContext } from "react";
import { Logger } from "@feature-hub/core";

import { ITrackingServiceV1 } from "@volkswagen-onehub/audi-tracking-service";
import { AudiFootnoteReferenceServiceInterfaceV3 } from "@volkswagen-onehub/audi-footnote-reference-service";

import { Dependencies } from "../index";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import { LocaleServiceV1 } from "@volkswagen-onehub/locale-service";
import { RenderMode } from "@volkswagen-onehub/audi-render-mode-service";

interface State {
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly logger?: Logger;
  readonly trackingService?: ITrackingServiceV1;
  readonly footnoteScope?: AudiFootnoteReferenceServiceInterfaceV3;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly localeService: LocaleServiceV1;
  readonly renderMode: RenderMode;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({
  featureServices: {
    "s2:logger": logger,
    "audi-tracking-service": trackingService,
    "dbad:audi-vue-formatter-service": vueFormatterService,
    "audi-render-mode-service": renderModeService,
    "locale-service": localeService,
  },
  children,
}: ContextValue): JSX.Element => {
  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = {
    inViewRef,
    logger,
    trackingService,
    vueFormatterService,
    localeService,
    renderMode: renderModeService?.getRenderMode() || RenderMode.DEFAULT,
  };

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => {
  return useContext(Context).logger;
};

export const useRenderMode = (): RenderMode | undefined => {
  return useContext(Context).renderMode;
};
