(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("@audi/audi-ui-react"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "styled-components", "@audi/audi-ui-react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("styled-components"), require("@audi/audi-ui-react")) : factory(root["react"], root["styled-components"], root["@audi/audi-ui-react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__608__, __WEBPACK_EXTERNAL_MODULE__524__) {
return 