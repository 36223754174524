import { Content, Variant } from "../EditorContentTypes";
import { HighlightedCarTeaserFeatureAppProps } from "../feature-app";

const mapContent = (
  variant: Variant,
  isPersonalized = false
): HighlightedCarTeaserFeatureAppProps => {
  return {
    ...variant,
    isPersonalized,
  };
};

const findVariant = (content: Content, contentId: string) => {
  if (contentId === "default") {
    return content.default;
  }
  return content.personalizedVariants.find(
    (item) => item.contentId === contentId
  );
};

const getPersonalizedContent = (
  content: Content,
  contentId?: string
): HighlightedCarTeaserFeatureAppProps => {
  if (contentId) {
    const variant = findVariant(content, contentId);
    if (variant) {
      return mapContent(variant, true);
    }
  }
  return mapContent(content.default);
};

const getPreviewVariantContent = (
  content: Content,
  previewVariant: string
): HighlightedCarTeaserFeatureAppProps => {
  const variant = findVariant(content, previewVariant);
  if (!variant) {
    throw new Error(
      `No matching content variant found for previewVariant contentId "${previewVariant}"`
    );
  }
  return mapContent(variant, true);
};

export { getPersonalizedContent, getPreviewVariantContent };
