/* eslint-disable react/display-name */
import { AsyncSsrManagerV1 } from "@feature-hub/async-ssr-manager";
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from "@feature-hub/core";
import { ReactFeatureApp } from "@feature-hub/react";
import { SerializedStateManagerV1 } from "@feature-hub/serialized-state-manager";
import { ContentServiceV1 } from "@volkswagen-onehub/audi-content-service";
import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
  PersonalizationContextProvider,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from "@volkswagen-onehub/audi-footnote-reference-service";
import { IPersonalizationServiceV1 } from "@volkswagen-onehub/audi-personalization-service";
import { RenderModeServiceV1 } from "@volkswagen-onehub/audi-render-mode-service";
import { ITrackingServiceV1 } from "@volkswagen-onehub/audi-tracking-service";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import { LocaleServiceV1 } from "@volkswagen-onehub/locale-service";
import React from "react";
import { HighlightedCarTeaserProps } from "./component/ComponentTypes";
import { ContextProvider } from "./context";
import { PreviewVariantContextProvider } from "./context/usePreviewVariant";
import { HighlightedCarTeaserFeatureApp } from "./feature-app";
import { createInitialState, deserializeState } from "./utils/stateUtils";

export interface Dependencies extends FeatureServices {
  readonly "s2:logger"?: Logger;
  readonly "audi-personalization-service"?: IPersonalizationServiceV1;
  readonly "audi-tracking-service"?: ITrackingServiceV1;
  readonly "audi-content-service": ContentServiceV1;
  readonly "audi-render-mode-service": RenderModeServiceV1;
  readonly "audi-footnote-reference-service": AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly "dbad:audi-vue-formatter-service": VueFormatterServiceInterfaceV1;
  readonly "locale-service": LocaleServiceV1;
  readonly "s2:async-ssr-manager"?: AsyncSsrManagerV1;
  readonly "s2:serialized-state-manager"?: SerializedStateManagerV1;
}

export interface Config {
  readonly previewVariant?: string;
}

const HighlightedCarTeaserFeatureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Config
> = {
  dependencies: {
    externals: {
      "@audi/audi-ui-react": "^1.4.0",
      "@feature-hub/react": "^2.7.0",
      react: "^16.13.1",
      "react-dom": "^16.13.1",
      "styled-components": "^5.1.1",
    },
    featureServices: {
      "audi-content-service": "^1.0.0",
    },
  },
  optionalDependencies: {
    featureServices: {
      "s2:logger": "^1.0.0",
      "s2:async-ssr-manager": "^1.0.0",
      "s2:serialized-state-manager": "^1.0.0",
      "audi-personalization-service": "^1.0.0",
      "audi-tracking-service": "^1.0.0",
      "audi-footnote-reference-service": "3.0.0",
      "dbad:audi-vue-formatter-service": "1.0.0",
      "locale-service": "^1.1.0",
      "audi-render-mode-service": "^1.0.0",
    },
  },

  create: ({ featureServices, config }) => {
    const {
      "audi-content-service": contentService,
      "audi-tracking-service": trackingService,
      "s2:logger": logger,
      "audi-footnote-reference-service": footnoteReferenceServiceScopeManager,
      "audi-personalization-service": personalizationService,
      "s2:async-ssr-manager": asyncSsrManager,
      "s2:serialized-state-manager": serializedStateManager,
      "dbad:audi-vue-formatter-service": vueFormatterService,
      "locale-service": localeService,
    } = featureServices;

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = "audi-highlighted-car-teaser";
    }

    const content = contentService.getContent();
    const footnoteReferenceService = footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();
    let hctProps: HighlightedCarTeaserProps | undefined;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            hctProps = await createInitialState(
              content,
              vueFormatterService,
              localeService,
              logger
            );
            if (hctProps) {
              serializedStateManager?.register(() => JSON.stringify(hctProps));
            }
          }
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        hctProps = deserializeState(serializedState);
        logger?.info("SSR serialized state: ", hctProps);
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken \n 3. The HCT is only SSR when no personalized variants exist`
        );
      }
    }

    const render = () => (
      <InViewContextProvider logger={logger}>
        <PersonalizationContextProvider
          personalizationService={personalizationService}
          useCase="highlighted-car-teaser"
        >
          <ContentContextProvider contentService={contentService}>
            <FootnoteContextProvider
              footnoteReferenceService={footnoteReferenceService}
            >
              <div
                data-fefa-custom-id={footnoteReferenceService.getConsumerId()}
              >
                <PreviewVariantContextProvider
                  previewVariant={config?.previewVariant}
                >
                  <ContextProvider featureServices={featureServices}>
                    <HighlightedCarTeaserFeatureApp props={hctProps} />
                  </ContextProvider>
                </PreviewVariantContextProvider>
              </div>
            </FootnoteContextProvider>
          </ContentContextProvider>
        </PersonalizationContextProvider>
      </InViewContextProvider>
    );

    return {
      render,
    };
  },
};

export default HighlightedCarTeaserFeatureAppDefinition;
