import { AudiPlatformProvider } from "@audi/audi-ui-react";
import {
  getConsumptionsAndEmissions,
  transformToTrackingText,
  useContent,
  useInViewEffect,
  usePersonalizationContext,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as pkg from "../package.json";
import { HighlightedCarTeaserProps } from "./component/ComponentTypes";
import { DeprecationMessage } from "./component/DeprecationMessage";
import { HighlightedCarTeaser } from "./component/index";
import { HighlightedCarTeaserSkeleton } from "./component/skeleton";
import { Context } from "./context";
import {
  PreviewVariantMatchingMessage,
  usePreviewVariant,
} from "./context/usePreviewVariant";
import { useTrackingManager } from "./context/useTrackingManager";
import { Content, Variant } from "./EditorContentTypes";
import {
  getPersonalizedContent,
  getPreviewVariantContent,
} from "./utils/getPersonalizedContent";
import { mapToTeaserProps } from "./utils/mapToTeaserProps";

export interface HighlightedCarTeaserFeatureAppProps extends Variant {
  readonly isPersonalized: boolean;
}

const getImageToTrack = (desktop: string, mobile: string) => {
  return window.innerWidth > 768 ? desktop : mobile;
};

export const HighlightedCarTeaserFeatureApp: React.FunctionComponent<{
  props?: HighlightedCarTeaserProps;
}> = ({ props }: { props?: HighlightedCarTeaserProps }) => {
  const { vueFormatterService, localeService, logger } = useContext(Context);
  const trackingManager = useTrackingManager();

  const content = useContent<Content>();
  const [selectedContentVariant, setSelectedContentVariant] = useState<
    HighlightedCarTeaserFeatureAppProps
  >();
  const [teaserProps, setTeaserProps] = useState<
    HighlightedCarTeaserProps | undefined
  >(props);
  const previewVariant = usePreviewVariant();
  const [previewVariantError, setPreviewVariantError] = useState(false);
  const { useCase, personalizationInfo } = usePersonalizationContext();

  useEffect(() => {
    trackingManager.ready(pkg.version);
  }, []);
  useEffect(() => {
    if (content) {
      if (previewVariant) {
        try {
          const personalizedContent = getPreviewVariantContent(
            content,
            previewVariant
          );
          setSelectedContentVariant(personalizedContent);
        } catch ({ message }) {
          logger?.error(message);
          setPreviewVariantError(true);
        }
      } else if (props) {
        setTeaserProps(props);
        setSelectedContentVariant({
          ...content.default,
          isPersonalized: false,
        });
      } else {
        const personalizedContent = getPersonalizedContent(
          content,
          personalizationInfo?.variant
        );
        setSelectedContentVariant(personalizedContent);
      }
    }
  }, [content]);

  useEffect(() => {
    let isMounted = true;
    if (selectedContentVariant) {
      getConsumptionsAndEmissions(
        selectedContentVariant.legalData.wltpKeys,
        vueFormatterService,
        localeService,
        logger
      ).then((consumptionsAndEmissions) => {
        if (isMounted && content) {
          const teaserProps = mapToTeaserProps(
            selectedContentVariant,
            consumptionsAndEmissions,
            content.spacingOption
          );
          setTeaserProps(teaserProps);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [selectedContentVariant]);

  useInViewEffect(() => {
    if (selectedContentVariant && teaserProps) {
      trackingManager.impression(
        transformToTrackingText(selectedContentVariant.headline),
        getImageToTrack(
          teaserProps.image.imageDesktop,
          teaserProps.image.imageMobile
        ),
        selectedContentVariant.isPersonalized,
        useCase,
        personalizationInfo?.trackParam,
        personalizationInfo?.iterationId,
        teaserProps.carIdentifiers.modelShortcode,
        teaserProps.carIdentifiers.carlineGroup
      );
    }
  });

  const linkTracking = useCallback(
    (href: string, text: string, clickId: string): void => {
      if (selectedContentVariant && teaserProps) {
        trackingManager.click(
          transformToTrackingText(selectedContentVariant.headline),
          getImageToTrack(
            teaserProps.image.imageDesktop,
            teaserProps.image.imageMobile
          ),
          href,
          text,
          clickId,
          selectedContentVariant.isPersonalized,
          useCase,
          personalizationInfo?.trackParam,
          personalizationInfo?.iterationId,
          teaserProps.carIdentifiers.modelShortcode,
          teaserProps.carIdentifiers.carlineGroup
        );
      }
    },
    [teaserProps]
  );
  return (
    <AudiPlatformProvider>
      <DeprecationMessage />
      {previewVariant && (
        <PreviewVariantMatchingMessage error={previewVariantError} />
      )}
      {teaserProps && (
        <HighlightedCarTeaser {...teaserProps} linkTracking={linkTracking} />
      )}
      {!teaserProps && <HighlightedCarTeaserSkeleton />}
    </AudiPlatformProvider>
  );
};
HighlightedCarTeaserFeatureApp.displayName = "HighlightedCarTeaserFeatureApp";
