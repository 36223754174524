import * as React from "react";
import styled from "styled-components";
import { Text } from "@audi/audi-ui-react";
import { useRenderMode } from "../context";

const StyledMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.ui.error};
  padding: var(${({ theme }) => theme.responsive.spacing.s})
    var(${({ theme }) => theme.responsive.spacing.l});

  h1 {
    color: white !important;
  }
`;

export const DeprecationMessage: React.FunctionComponent = () => {
  const [renderMode] = React.useState(useRenderMode());

  if (!renderMode) {
    return null;
  }

  return (
    <StyledMessage>
      <Text as="h1" variant="order3">
        This Feature App is deprecated. Please use{" "}
        <a href="https://cms1-author.audi.com/dam/nemo/cq-help/manuals/Highlighted_Car_Teaser_Migration_Handbook.pdf">
          the migration handbook
        </a>{" "}
        to learn how to migrate to the new version of the Highlighted Car
        Teaser.
      </Text>
    </StyledMessage>
  );
};
