import { ReactNodeArray, createElement } from "react";
import { Logger } from "@feature-hub/core";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import { LocaleServiceV1 } from "@volkswagen-onehub/locale-service";
import {
  getConsumptionsAndEmissions,
  FootnoteReference,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

import { Content } from "../EditorContentTypes";
import { HighlightedCarTeaserProps } from "../component/ComponentTypes";
import { mapToTeaserProps } from "./mapToTeaserProps";

export async function createInitialState(
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: LocaleServiceV1,
  logger?: Logger
): Promise<HighlightedCarTeaserProps | undefined> {
  if (content.personalizedVariants.length > 0) {
    return undefined;
  }

  const consumptionsAndEmissions = await getConsumptionsAndEmissions(
    content.default.legalData.wltpKeys,
    vueFormatterService,
    localeService,
    logger
  );
  return mapToTeaserProps(
    content.default,
    consumptionsAndEmissions,
    content.spacingOption
  );
}

interface SerializedWltpProps {
  formattedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown>)[] | undefined;
}

const deserializeReactNodeArray = (
  serializedProperty?: string | (string | Record<string, unknown>)[]
): undefined | string | ReactNodeArray => {
  if (!serializedProperty || typeof serializedProperty === "string") {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return serializedProperty;
  }
  return serializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === "string") {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(
      FootnoteReference,
      serializedReactNode.props as undefined
    );
  });
};

const deserializeReactNodeArrayInWltpData = (
  wltpData: SerializedWltpProps[]
) => {
  return wltpData.map(({ formattedConsumption, formattedEmission }) => {
    return {
      formattedConsumption: deserializeReactNodeArray(formattedConsumption),
      formattedEmission: deserializeReactNodeArray(formattedEmission),
    };
  });
};

export function deserializeState(state: string): HighlightedCarTeaserProps {
  const props = JSON.parse(state);
  return {
    ...props,
    headline: deserializeReactNodeArray(props.headline),
    additionalLegalText: deserializeReactNodeArray(props.additionalLegalText),
    wltpData: deserializeReactNodeArrayInWltpData(props.wltpData),
  };
}
